<template>
  <div class="info-callout">
    <div class="content">
      <h2>{{ title }}</h2>
      <vue3-markdown-it v-if="subhead" :source="subhead" class="subhead" />
      <vue3-markdown-it v-if="text" :source="text" class="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoCallout",
  props: {
    title: String,
    subhead: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style-vars.scss";
.info-callout {
  border-width: 1px 0;
  border-style: solid;
  border-color: $light-gray;
  padding: 6rem 2rem;
  margin: 6rem 4rem;
  .content {
    max-width: 420px;
    margin: 0 auto;
    h2 {
      font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      font-weight: 500;
      font-size: 2.2rem;
    }
    .subhead {
      font-size: 1.8rem;
    }
    .text {
      font-size: 1.4rem;
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .info-callout {
    .content {
      .subhead {
        font-size: 2.2rem;
      }
      .text {
        font-size: 2rem;
      }
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
