<template>
  <div class="page letters">
    <div class="section-header">
      <h1>Letters</h1>
      <h2 class="section-tagline">What would other earthlings say?</h2>
      <div class="letters-section-intro">
        <div class="letter-frame rot-2">
          <p class="salutations">Dear human</p>
          <p>
            Please join us in our efforts to address the climate in new
            ways—literally: write a letter to the climate and send it to us.
          </p>
          <p>
            Write from anyone or anything: yourself, our species, another
            species of animal or plant, a place, a thing, an atmospheric
            phenomenon, your electric guitar, your favorite massage oil,
            Nietzsche, Al Gore, The CEO of BP, the Dalai Lama...
          </p>
          <p>
            Use any form you want: prose, verse, blank verse, a list, a memo, a
            txt. msg., a tweet, a short story, a monologue, a jeremiad, a song,
            a love letter, an official complaint...
          </p>
          <p>Any length, any language. No holds barred. No trigger warnings.</p>
          <p>The climate looks forward to hearing from you, and so do we.</p>
          <p class="valediction">
            Love and thanks,<br />
            Marina, Una, Fritz, and Oliver
          </p>
        </div>
      </div>
    </div>
    <!-- CALL TO SUBMIT LETTERS -->
    <InfoCallout
      title="Submit a Letter"
      subhead="Send your contributions to [dearclimate@gmail.com](mailto:dearclimate@mgmail.com)."
      text="If we feature your contribution on this website, we’ll be in touch
        with you for permission."
    />
    <!-- LETTERS GALLERY -->
    <div class="letter-gallery">
      <h1>Some responses to our invitation</h1>
      <LetterItem
        v-for="letter in letters"
        :key="'letter' + letter.id"
        :id="letter.id"
        :letterBody="letter.letterBody"
        :contributor="letter.contributor"
      />
    </div>
  </div>
</template>
<script>
import LetterItem from "@/components/LetterItem.vue";
import InfoCallout from "@/components/InfoCallout.vue";

import lettersJSON from "@/page-contents/letters.json";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Letters`,
      description: `WHAT WOULD OTHER EARTHLINGS SAY?`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/letters`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "App",
  components: {
    LetterItem,
    InfoCallout,
  },
  data: function () {
    return {
      letters: [],
    };
  },
  methods: {
    loadLetters(jsonData) {
      for (let i = 0; i < jsonData.data.length; i++) {
        let thisLetter = {};
        thisLetter.id = jsonData.data[i].id;
        if (jsonData.data[i].attributes.contributor) {
          thisLetter.contributor = jsonData.data[i].attributes.contributor;
        } else {
          thisLetter.contributor = null;
        }

        thisLetter.letterBody = jsonData.data[i].attributes.letter;
        console.log();
        this.letters.push(thisLetter);
      }
      console.log(`Letters loaded. Total ${this.letters.length} letters.`);
    },
  },
  mounted() {
    this.loadLetters(lettersJSON);
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  margin-left: 0;
  margin-right: 0;
}
.letters-section-intro {
  max-width: 760px;
  margin: 0 auto 14rem auto;
  padding: 4rem 2rem;
  text-align: left;
  overflow: hidden;
  .letter-frame {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 7rem 1rem 7rem 4rem;
    background-color: rgb(247, 247, 239);
    background-image: linear-gradient(
        90deg,
        transparent 29px,
        #abced4 29px,
        #abced4 31px,
        transparent 31px
      ),
      linear-gradient(#eee 0.1em, transparent 0.1em);
    background-size: 100% 1.2em;
  }
  .salutations {
    font-size: 2.2rem;
  }
  p {
    font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
      monospace;
    font-size: 1.6rem;
    font-weight: 600;
    color: rgb(32, 32, 45);
  }
  .valediction {
  }
}

.letter-gallery {
  padding: 2rem;
  h1 {
    font-size: 2.8rem;
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .letters-section-intro {
    max-width: 760px;
    margin: 0 auto 14rem auto;
    overflow: visible;
    .letter-frame {
      padding: 7rem 4rem 7rem 12rem;
      background-image: linear-gradient(
          90deg,
          transparent 79px,
          #abced4 79px,
          #abced4 81px,
          transparent 81px
        ),
        linear-gradient(#eee 0.1em, transparent 0.1em);
      background-size: 100% 1.2em;
    }
    p {
      font-size: 1.8rem;
    }
  }
  .letter-gallery {
    padding: 2rem;
    h1 {
      font-size: 3.6rem;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
